import React from "react";
import { graphql, Script } from "gatsby";
import SEO from "../components/seo";
import try_free_bg from "../assets/images/try_free_bg.png";
import { BackIcon } from "../assets/icons/backIcon";
import { CheckIcon } from "../assets/icons/checkIcon";
import CustomLink from "../components/CustomLink";

const TrialSucccessContent = ({ data }) => {
  const _data =
    data?.allPrismicTrialContactSuccess?.edges &&
    data?.allPrismicTrialContactSuccess?.edges[0]
      ? data?.allPrismicTrialContactSuccess?.edges[0]?.node?.data
      : {};
  const [features] = _data?.body?.filter(
    (v) => v.slice_type === "features_descriptions"
  ) || [[]];
  const [brands] = _data?.body?.filter(
    (v) => v.slice_type === "trusted_brands_list"
  ) || [[]];

  return (
    <section>
      <div className="h-screen !text-left grid grid-cols-12">
        <div className="col-span-12 lg:col-span-7 relative">
          <img
            src={try_free_bg}
            alt="background"
            className="absolute inset-0 w-full h-full -z-10 bg-[#212233]"
            loading="lazy"
            width={0}
            height={0}
          />
          <section className="pt-10 md:pt-16 pb-10 md:pb-0 px-8 md:px-16">
            <CustomLink to="/" className="flex items-center gap-2 z-10">
              <span>
                <BackIcon />
              </span>
              <h4 className="text-xl font-semibold text-white leading-6 tracking-[-0.04em]">
                Back
              </h4>
            </CustomLink>
            <div
              dangerouslySetInnerHTML={{ __html: _data?.page_heading?.html }}
              className="text_bg font-semibold text-[40px] md:text-[80px] mt-20 leading-[70px] md:leading-[96.82px] text-white"
            />

            <section className="mt-9 grid grid-cols-1 sm:grid-cols-2 gap-6 pb-6 border-b-[0.5px] border-b-slate-500">
              {features?.items?.map((data, index) => (
                <section className="flex gap-3 col-span-1" key={index}>
                  <span className="mt-[3px]">
                    <CheckIcon />
                  </span>
                  <p className="text-base leading-7 text-white">
                    {data?.feature_description_text?.text}
                  </p>
                </section>
              ))}
            </section>

            <p className="mt-11 text-base font-semibold text-center leading-7 text-white">
              Used by the world’s leading computer vision teams to accelerate
              model development
            </p>

            <div className={`mt-6 grid grid-cols-2 gap-8 md:grid-cols-6`}>
              {brands?.items?.map((x, i) => {
                return (
                  <div
                    key={i}
                    className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1"
                  >
                    <img
                      src={x?.brand_logo?.url}
                      alt={x?.brand_logo?.alt || `brand_icon_${i}`}
                      className="object-scale-down h-24 w-96 image_grayscale"
                      loading="lazy"
                      width={0}
                      height={0}
                    />
                  </div>
                );
              })}
            </div>
          </section>
        </div>

        <div className="col-span-12 lg:col-span-5 my-auto px-[50px]">
          <div
            className="meetings-iframe-container !h-[600px] xl:!h-[656px]"
            data-src="https://meetings-eu1.hubspot.com/ilaria-occhiolini?embed=true"
          />
          <Script
            type="text/javascript"
            defer
            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js"
          />
        </div>
      </div>
    </section>
  );
};

const ContactSuccess = (props) => {
  return <TrialSucccessContent {...props} />;
};

export const query = graphql`
  query {
    allPrismicTrialContactSuccess {
      edges {
        node {
          data {
            body {
              ... on PrismicTrialContactSuccessDataBodyContactSalesQuotes {
                id
                slice_type
                items {
                  icon {
                    alt
                    url
                    gatsbyImageData(width: 400, placeholder: BLURRED)
                  }
                  quote {
                    html
                    text
                  }
                }
              }
              ... on PrismicTrialContactSuccessDataBodyTrustedBrandsList {
                id
                slice_type
                items {
                  brand_logo {
                    alt
                    url
                    gatsbyImageData(width: 400, placeholder: BLURRED)
                  }
                }
              }
              ... on PrismicTrialContactSuccessDataBodyFeaturesDescriptions {
                id
                slice_type
                items {
                  feature_description_text {
                    html
                    text
                  }
                }
              }
            }
            description {
              html
              text
            }
            page_heading {
              html
              text
            }
            quotes_heading {
              html
              text
            }
          }
        }
      }
    }
  }
`;

export const Head = () => {
  return (
    <SEO
      title="Thanks for requesting a demo | Encord"
      description="Thanks for booking a time for your Encord trial onboarding session"
    />
  );
};

export default ContactSuccess;
